.bulk-actions-fixed {
  @media (min-width: 767.98px) {
    position: absolute !important;
    z-index: 10;
    top: 18px;
    right: 10px;
    width: 30vw;

    .col-sm-4 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}
