@import "./dashboard/workload";
@import "./datatable/datatable";
@import "./datatable/bulk-actions";


.page-header-sticky {
  position: sticky;
  top: 0px;
  z-index: 100;
}

.content-wrapper-sticky {
  overflow: initial;
}