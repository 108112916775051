.wb-container {
  margin-bottom: 30px;
}

.wb-header {
  display: flex;
  align-items: center;

  @media (max-width: 991px) {
    align-items: flex-start;
    flex-direction: column;
  }

  h4 {
    margin: 0;
  }
}

.wb-header--stats-summary {
  font-size: 1.1875rem;
  font-weight: 400;
  margin-left: 10px;

  @media (max-width: 991px) {
    margin-left: 0px;
  }
}

.wb-chart {
}

.wb-chart--bar {
  position: relative;
  width: 100%;
  height: 30px;
  border-radius: 5px;
  background-color: #232f33;
}

.wb-chart--progress {
  position: absolute;

  transition: all 1s !important;

  &:not(:last-child) {
    border-right: 1px solid black;
  }

  &:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  &:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

.wb-chart--progress-status-bar {
  height: 30px;
  position: absolute;
  transition: all 1s !important;
  &:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  &:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

.wb-chart--progress-bar {
  padding-left: 5px;
  height: 30px;
  display: flex;
  align-items: center;
  color: #fff;

  overflow-wrap: initial;
  overflow-x: clip;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: all 1s !important;
}

.wb-chart--progress-hours {
  bottom: -16px;
  left: 0;
  position: absolute;
  color: #000;
  padding-left: 5px;
  overflow-wrap: initial;
  overflow-x: clip;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  transition: all 1s !important;
}

.wb-chart--status {
  display: inline-block;
  overflow-wrap: initial;
  overflow-x: clip;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: -5px;
  transition: all 1s !important;
}

.wb-chart--progress-tooltip {
  position: absolute;
}
.wb-chart--progress-tooltip + .tooltip {
  pointer-events: none;
}
